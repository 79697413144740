<template>
    <div>
        <h1>Inshore bookings</h1>

        <div v-if="isTableLoading" class="text-center pt-3">
            <b-spinner label="Loading..." />
        </div>

        <template v-else>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input
                                v-model="searchTerm"
                                placeholder="Search"
                                type="text"
                                class="d-inline-block"
                        />
                    </div>
                </b-form-group>
            </div>

            <vue-good-table
                    :columns="columns"
                    :rows="bookingsWithPayments"
                    :search-options="{
                      enabled: true,
                      externalQuery: searchTerm,
                    }"
                    :pagination-options="{
                      enabled: true,
                      perPage: pageLength,
                    }"
                    @on-column-filter="onColumnFilter"
                    @on-row-click="clickRow"
            >
                <template slot="table-column" slot-scope="props">
                  <span v-if="props.column.label === 'Customer'"
                          class="d-flex align-items-center"
                    >
                    <span class="mr-25">
                      {{props.column.label}}
                    </span>
                    <feather-icon
                            icon="InfoIcon"
                            size="16"
                            v-b-tooltip.hover
                            class="text-primary"
                            title="Click on a row in this column to see customer details"
                    />
                  </span>
                  <span v-else-if="props.column.label === 'Status'"
                            class="d-flex align-items-center"
                    >
                    <span class="mr-25">
                      {{props.column.label}}
                    </span>
                    <feather-icon
                            icon="InfoIcon"
                            size="16"
                            v-b-tooltip.hover
                            class="text-primary"
                            title="Click on a row in this column to see payment history"
                    />
                  </span>

                  <span v-else>
                      {{props.column.label}}
                  </span>
                </template>
                <template slot="column-filter" slot-scope="{ column, updateFilters }">
                    <b-form-datepicker
                            v-if="column.filterOptions ? column.filterOptions.customFilter : false"
                            locale="en"
                            size="sm"
                            placeholder=""
                            reset-button
                            @input="(value) => updateFilters(column, value)"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />
                </template>

                <template slot="table-row" slot-scope="props">
                    <!-- Column: Name -->
                    <span v-if="props.column.field === 'customer_name'"
                            class="text-nowrap"
                            @click="fieldCustomerDetails(props.row)">
                      {{ props.row.customer_name }}
                    </span>

                    <!-- Column: Status -->
                    <span v-else-if="props.column.field === 'status'">
                        <b-badge :variant="status[props.row.status].class">
                          {{ status[props.row.status].status }}
                        </b-badge>
                    </span>

                    <span v-else-if="props.column.field === 'deposit'">
                      <b-button variant="outline-secondary" v-if="props.row.status >= 2" v-b-modal.deposit-modal @click="showPaidAmount(props.row)">Yes</b-button>
                      <span v-else>No</span>
                    </span>
                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                        <span>
                          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
                            <template v-slot:button-content>
                              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25"/>
                            </template>
                            <b-dropdown-item v-if="props.row.status !== 6" v-b-modal.edit-modal @click="openEditModal(props.row)">
                              <feather-icon icon="Edit2Icon" class="mr-50" />
                              <span>Edit</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="resendBookingEmail(props.row.id)">
                              <feather-icon icon="MailIcon" class="mr-50" />
                              <span>Resend email</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="deleteItem(props.row.id)">
                              <feather-icon icon="TrashIcon" class="mr-50" />
                              <span>Delete</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </span>
                      </span>

                      <!-- Column: Common -->
                      <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                      </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-end flex-wrap">
                        <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value) => props.pageChanged({ currentPage: value })"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </template>
            </vue-good-table>
        </template>

        <b-modal id="edit-modal" :title='"Edit booking details #" + edited.id'>
            <b-form>
                <b-form-checkbox v-model="edited.cancelled" switch class="mb-1">
                    Cancel this booking?
                </b-form-checkbox>
                <template v-if="edited.cancelled && edited.boat === 0 && edited.status.id !== 1">
                    <b-form-checkbox v-model="edited.refundInshore" switch>
                        Make refund?
                    </b-form-checkbox>
                </template>
                <template v-if="!edited.cancelled">
                    <b-form-group label="Status" label-for="status">
                        <v-select
                            id="status-select"
                            v-model="edited.status"
                            label="status"
                            :options="Object.values(statusSelect)"
                            :clearable="false"
                        />
                    </b-form-group>
                    <b-form-group label="Start date">
                        <b-form-datepicker
                            id="start_at-datepicker"
                            v-model="edited.date"
                            :min="minDate"
                            :date-disabled-fn="dateDisabled"
                            :disabled="isDatepickerDisabled"
                            :placeholder="datepickerPlaceholder"
                            locale="en"
                            @input="dateChanged"
                        />
                    </b-form-group>
                </template>
            </b-form>
            <template #modal-footer>
                <b-button
                        variant="outline-secondary"
                        class="float-right"
                        @click="$bvModal.hide('edit-modal')"
                >
                    Close
                </b-button>
                <b-button
                        variant="primary"
                        class="float-right"
                        @click="editSubmit"
                        :disabled="isEditLoading"
                >
                    <b-spinner v-if="isEditLoading" label="Spinning" small />
                    <span v-else> Save </span>
                </b-button>
            </template>
        </b-modal>

      <b-modal id="deposit-modal" :title='"Deposit paid for #" + paidAmount.bookingId'>
        <b-form>
          <template>
              <h1>${{ paidAmount.amount }}</h1>
          </template>
        </b-form>
        <template #modal-footer>
          <b-button variant="outline-secondary" class="float-right" @click="$bvModal.hide('deposit-modal')">
            Close
          </b-button>
        </template>
      </b-modal>



        <b-modal
                id="customer-modal"
                ok-only
                title="Customer's Details"
                cancel-variant="outline-secondary"
        >
            <b-form-group
                    label="Name"
                    class="capitalize"
            >
                <b-form-input
                        v-model="customer.name"
                        readonly
                />
            </b-form-group>
            <b-form-group
                    label="Email"
                    class="capitalize"
            >
                <b-form-input
                        v-model="customer.email"
                        readonly
                />
            </b-form-group>
            <b-form-group
                    label="Phone"
                    class="capitalize"
            >
                <b-form-input
                        v-model="customer.phone"
                        readonly
                />
            </b-form-group>

            <b-form-group
                    label="Stripe Id"
                    class="capitalize"
            >
                <b-form-input
                        v-model="customer.stripe_id"
                        readonly
                />
            </b-form-group>

        </b-modal>
    </div>
</template>

<script>
    import {
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BDropdown,
        BDropdownItem,
        VBModal,
        BForm,
        BFormDatepicker,
        BSpinner,
        BButton,
        BFormCheckbox,
        BTable,
        VBTooltip,
    } from "bootstrap-vue";
    import vSelect from "vue-select";
    import { VueGoodTable } from "vue-good-table";
    import { mapActions, mapGetters } from "vuex";
    import axios from "@/libs/axios";
    import { toastSuccess, toastFailure } from "@/libs/toastification";
    import {sweetWarning, sweetError} from "@/libs/sweet-alerts";
    import {checkReservedDate, isTimeSelectable} from "@/libs/reserved-dates";
    import {FULL_DAY_TRIP, THREE_FOURTHS_DAY_TRIP, HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID,
        INSHORE_NAME,
        INSHORE_BASE_PEOPLE,
        INSHORE_BASE_HOURS,
        INSHORE_BASE_COST,
        INSHORE_ADD_COST,
        INSHORE_MAX_PEOPLE,
        INSHORE_MAX_HOURS,
        INSHORE_START_TIME,
        INSHORE_DEPOSIT,
        INSHORE_DURATION_OPTIONS} from "@/constants/trip-types.enum";

    export default {
        components: {
            VueGoodTable,
            BBadge,
            BPagination,
            BFormGroup,
            BFormInput,
            BDropdown,
            BDropdownItem,
            BForm,
            vSelect,
            BFormDatepicker,
            BSpinner,
            BButton,
            BFormCheckbox,
            BTable,
        },
        directives: {
            "b-modal": VBModal,
            "b-tooltip": VBTooltip,
        },
        data() {
            return {
                HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID, FULL_DAY_TRIP, THREE_FOURTHS_DAY_TRIP,
                INSHORE_NAME,
                INSHORE_BASE_PEOPLE,
                INSHORE_BASE_HOURS,
                INSHORE_BASE_COST,
                INSHORE_ADD_COST,
                INSHORE_MAX_PEOPLE,
                INSHORE_MAX_HOURS,
                INSHORE_START_TIME,
                INSHORE_DEPOSIT,
                INSHORE_DURATION_OPTIONS,
                durationOptions: INSHORE_DURATION_OPTIONS,
                packages: null,
                isTableLoading: true,
                isEditLoading: false,
                refundButtonDisabled: true,
                refundAlsoCancel:false,
                searchTerm: "",
                boatFilterOptions:[''],
                columns: [
                    {
                        label: "Charter #",
                        field: "id",
                        type: "number",
                        thClass: "text-left",
                        tdClass: "text-left",
                    },
                    {
                        label: "Customer",
                        field: "customer_name",
                        tdClass: "customer-name",
                    },
                    {
                        label: "Date",
                        field: "date",
                        width: '140px',
                        type: 'date',
                        dateInputFormat: 'yyyy-MM-dd',
                        dateOutputFormat: 'yyyy-MM-dd',
                        filterOptions: {
                            customFilter: true
                        },
                    },
                    {
                        label: 'Time',
                        field: 'time',
                    },
                    {
                        label: 'Duration (Hours)',
                        field: 'duration',
                    },
                    {
                      label: 'Deposit Paid',
                      field: 'deposit',
                    },
                    {
                        label: 'Guests',
                        field: 'details',
                    },
                    {
                        label: "Status",
                        field: "status",
                        type: "number",
                        tdClass: "payment-status",
                        filterOptions: {
                            enabled: true,
                            placeholder: "All",
                            filterDropdownItems: [
                                {
                                    value: 0,
                                    text: "Deposit Needed",
                                },
                                {
                                    value: 2,
                                    text: "Deposit Paid",
                                },
                                {
                                    value: 6,
                                    text: "Complete",
                                },
                                {
                                    value: 7,
                                    text: "Overpaid",
                                },
                                {
                                    value: 1,
                                    text: "Cancelled",
                                },
                            ],
                            trigger: "enter",
                        },
                    },
                    {
                        label: "Action",
                        field: "action",
                        thClass: "text-right pr-1",
                        tdClass: "text-right",
                        sortable: false,
                    },
                ],
                pageLength: 10,
                status: {
                    0: {
                        id: 0,
                        status: "Deposit Needed",
                        class: "light-primary",
                    },
                    1: {
                        id: 1,
                        status: "Cancelled",
                        class: "light-dark",
                    },
                    2: {
                        id: 2,
                        status: "Pending",
                        class: "light-success",
                    },
                    6: {
                        status: "Contracted",
                    },
                    7: {
                        status: "Overpaid",
                        class: "danger",
                    },
                },
                statusSelect: {
                    0: {
                        id: 0,
                        status: "Deposit Needed",
                    },
                    2: {
                        id: 2,
                        status: "Pending",
                    },
                    6: {
                        id: 6,
                        status: "Contracted",
                    }
                },
                edited: {
                    id: null,
                    boat: null,
                    date: null,
                    beforeDate: null,
                    charterPackage: null,
                    beforeCharterPackage:null,
                    status: null,
                    time: null,
                    cancelled: false,
                    refundInshore: false,
                    trip_id:null,
                    details:null,
                    boatCapacity:null,
                    boatObject:null,
                    duration:null
                },
                minDate: new Date(),
                blockDates: [],
                datepickerPlaceholder: "No date selected",
                isDatepickerDisabled: false,
                isPaymentHistoryLoading: true,
                paymentsHistoryFields: [
                    {
                        key: 'status',
                        formatter: value => {
                            return this.status[value].status
                        }
                    },
                    'date',
                    'amount',
                    'method',
                ],
                paymentsHistory: [],
                alreadyPaid: 0,
                refundedAmount:0,
                totalPrice: 0,
                remainingToPay: 0,
                bookingLogs:null,
                paymentStatus: null,
                customer: {
                    name: "",
                    email: "",
                    phone: "",
                    stripe_id: ""
                },
                paymentTypes: [
                    {
                        text: 'Deposit',
                        value: 2,
                    },
                    {
                        text: 'Installment',
                        value: 3,
                    },
                    {
                        text: 'Final payment',
                        value: 4,
                    },
                ],
                payment: {
                    bookingId: null,
                    type: null,
                    amount: 0,
                    method: null,
                },
                paymentMethods: [
                    'Credit card',
                    'Check',
                    'Cash',
                ],
                isAddPaymentLoading: false,
                firstApril2022: new Date(2022, 4, 1),
                thisBoatTripNameId:[],
                refundAmountShowHide: [],
                paidAmount: {
                  amount: null,
                  bookingId: null
                }
            };
        },
        computed: {
            ...mapGetters({
                boatNames: "boats/boatNames",
                bookings: "bookings/bookings",
                bookingsWithPayments: "bookings/bookingsWithPayments",
                boatNamesTripsPictures: 'boats/boatNamesTripsPictures',
            }),
            charterPackages() {

                const packages = [];

                if( this.boatNamesTripsPictures.length > 0 && this.edited.boat != null ){

                    const selectedBoatTripNameId = [];
                    let that = this;
                    this.boatNamesTripsPictures[this.edited.boat - 1].trips.map(function(trip){

                        selectedBoatTripNameId[trip.name] = trip.id;
                        if( that.edited.boat == TEASER_ID || (trip.name != HARBOR_CRUISE && trip.name != HALF_DAY_TRIP) ){
                            packages.push(trip.name);
                        }
                    });

                    this.thisBoatTripNameId = selectedBoatTripNameId;
                }

                return packages
            },
            times() {
                const timeOptions = [
                    {
                        value: "06:00:00",
                        text: "6 AM",
                        selectable: isTimeSelectable("6 AM", this.blockDates, this.edited),
                    },
                    {
                        value: "12:00:00",
                        text: "12 PM",
                        selectable: isTimeSelectable("12 PM", this.blockDates, this.edited),
                    }
                ];

                if (this.edited.charterPackage === HARBOR_CRUISE) {
                    timeOptions.push({
                        value: "18:00:00",
                        text: "6 PM",
                        selectable: isTimeSelectable("6 PM", this.blockDates, this.edited),
                    });
                }

                return timeOptions;
            },
        },
        watch: {
            'edited.boatObject.id': function () {
                this.loadBlockDates();
            },
            searchTerm(value) {
                this.changeUrl({ search: value });
            },
        },
        async mounted() {

            await this.loadInshoreTripsWithPayments().then((response)=>{}).catch((error) => {
                toastFailure("Something went wrong.");
            });
            await this.loadBoatNames().catch(() => {
                toastFailure("Something went wrong.");
            });

            await this.loadBoatNamesTripsPictures().then(()=>{

                }
            ).catch((error) => {
                console.log('error', error);
                toastFailure("Something went wrong.");
            });

            this.$set(
                this.columns[2].filterOptions,
                "filterDropdownItems",
                this.boatNames,
                this.boatNamesTripsPictures
            );

            /* eslint-disable-next-line */
            for (const [key, value] of Object.entries(this.$route.query)) {
                switch (key) {
                    case "search":
                        this.searchTerm = value;
                        break;
                    case "boat":
                        this.$set(this.columns[3].filterOptions, "filterValue", value);
                        break;
                    case "charter_package":
                        this.$set(this.columns[5].filterOptions, "filterValue", value);
                        break;
                    default:
                }
            }

            this.isTableLoading = false;
        },
        methods: {
            ...mapActions({
                loadBoatNames: "boats/loadBoatNames",
                loadInshoreTripsWithPayments: "bookings/loadInshoreTripsWithPayments",
                updateBooking: "bookings/updateBooking",
                resendEmail: "bookings/resendEmail",
                refundBookingPayment: "bookings/refundBookingPayment",
                deleteBooking: "bookings/deleteBooking",
                loadBoatNamesTripsPictures: 'boats/loadBoatNamesTripsPictures',
            }),
            returnBoatNames(){
                return [];
            },
            boatChanged() {

                const selectedBoatTripNameId = [];
                const packagesLocal = [];
                let that = this;
                this.boatNamesTripsPictures[this.edited.boatObject.id - 1].trips.map(function(trip){

                    selectedBoatTripNameId[trip.name] = trip.id;
                    if( that.edited.boatObject.id == TEASER_ID || (trip.name != HARBOR_CRUISE && trip.name != HALF_DAY_TRIP) ){

                        if( trip.inactive === 0 ) {
                            packagesLocal.push(trip.name);
                        }
                    }
                });

                this.thisBoatTripNameId = selectedBoatTripNameId;

                this.packages = packagesLocal;
            },
            async resendBookingEmail(bookingId){

                if (await this.confirmationMessage('Are you sure you want to resend an email for booking #'+bookingId+'?')) {

                    this.resendEmail({
                        id: bookingId
                    }).then(() => {
                        toastSuccess("Email successfully sent");
                    }).catch((error) => {
                        toastFailure("Something went wrong");
                    }).finally(() => {

                    });
                }

            },
            cellMoneyFormat(value) {
                return '$' + value;
            },
            showError(responseData=null, warning=false, title=null) {

                if( responseData != null && responseData.message != undefined && responseData.message != null ){

                    let errorMSG = responseData.message + '</br>';
                    if(responseData.errors){

                        Object.keys(responseData.errors).map(function(errorKey){
                            responseData.errors[errorKey].map(function(value, key){
                                errorMSG += value + '</br>';
                            });
                        });
                    }

                    if( warning ){
                        sweetWarning(title == null ? 'Warning' : title, errorMSG);
                    }
                    else{
                        sweetError(title == null ? 'Error'  : title, errorMSG);
                    }
                }
                else{
                    toastFailure("Something went wrong.");
                }
            },
            uncheckRow(row){
                setTimeout(function(){
                    row.vgtSelected = false;
                }, 100);
            },
            clickRowPayments(params){
                if( params.row.vgtDisabled === true ){

                    this.showError({message: '<br>Payment #'+params.row.id+' has no transaction id in database'}, 1, 'Refund must be processed manually');
                    this.uncheckRow(params.row);
                }
            },
            paymentRowsSelected(params){

                if( params.selectedRows.length > 0 ){

                    let that = this;
                    params.selectedRows.map(function(row){

                        if( row.vgtDisabled === false ){

                            if( that.refundAmountShowHide[row.id] == undefined ){
                                that.refundAmountShowHide[row.id] = new Array();
                            }

                            if( that.refundAmountShowHide[row.id]['visible'] == undefined || that.refundAmountShowHide[row.id]['visible'] == 'hide' ){
                                that.refundAmountShowHide[row.id]['visible'] = 'show';
                            }
                            else{
                                //that.refundAmountShowHide[row.id]['visible'] = 'hide';
                            }

                            that.refundAmountShowHide[row.id]['amount'] = row.amount;
                        }
                        else{
                            that.uncheckRow(row);
                        }

                    });

                    this.refundButtonDisabled = false;
                }
                else{

                    let that = this;
                    this.$refs['paymentsHistoryTable'].rows.map(function(row){
                        if( that.refundAmountShowHide[row.id] == undefined ){
                            that.refundAmountShowHide[row.id] = new Array();
                        }
                        that.refundAmountShowHide[row.id]['visible'] = 'hide';
                        that.uncheckRow(row);
                    });

                    this.refundButtonDisabled = true;
                }
            },
            async checkRefundPayments(){

                this.refundAlsoCancel = false;
                if( this.$refs['paymentsHistoryTable'].selectedRows.length > 0 ){
                    this.$root.$emit('bv::show::modal', 'refund-modal');
                }
            },
            refundPayments(){
                this.refundButtonDisabled = true;

                let selectedPayments = [];
                let that = this;
                this.$refs['paymentsHistoryTable'].selectedRows.map(function(selectedPayment){

                    selectedPayments.push({
                        'id': selectedPayment.id,
                        'amount': that.refundAmountShowHide[selectedPayment.id]['amount']
                    });
                });

                this.updateBooking({
                    id: this.paymentsHistory[0].booking_id,
                    status: this.paymentsHistory[0].status,
                    cancelled: (this.refundAlsoCancel === true ? 1 : 0),
                    refunded: true,
                }).then(() => {

                    this.refundBookingPayment({
                        booking_id: this.paymentsHistory[0].booking_id,
                        selected_payments: selectedPayments,
                        booking_canceled: this.refundAlsoCancel
                    }).then((response) => {
                        toastSuccess("Payment successfully refunded");
                    }).catch((error) => {
                        this.showError(error.response.data);
                    });
                }).catch((error) => {
                    this.showError(error.response.data);
                }).finally(() => {
                    this.isAddPaymentLoading = false;
                });
            },
            showPaidAmount(row) {

              let thisBookingPayments = this.bookingsWithPayments.find(x => x.id === row.id).payments;

              if (thisBookingPayments.length > 0) {
                this.paidAmount.amount = thisBookingPayments[0].amount;
              } else {
                this.paidAmount.amount = 'Unknown';
              }

              this.paidAmount.bookingId = row.id;
            },
            openEditModal(row) {
                this.edited.id = row.id;
                this.edited.boat = row.boat_id;
                this.edited.date = row.date;
                this.edited.beforeDate = row.date;
                this.edited.time = row.time;
                this.edited.charterPackage = row.charter_package;
                this.edited.beforeCharterPackage = row.charter_package;
                this.edited.trip_id = row.trip_id;
                this.edited.status = this.statusSelect[row.status];
                this.edited.cancelled = Boolean(row.cancelled);
                this.edited.details = row.details;
                this.edited.boatCapacity = row.boat_capacity;
                this.edited.boatObject = this.boatNamesTripsPictures.find(x => x.id === row.boat_id);
                this.edited.duration = row.duration;

                if( row.boat_id > 0 ){
                    this.boatChanged();
                    this.loadBlockDates(null, true);
                }
            },
            openAddPaymentModal(row) {
                this.payment.bookingId = row.id;
                this.payment.type = this.paymentTypes[0];
                this.payment.amount = 0;
                this.payment.method = 'Credit card';
            },
            async deleteItem(id) {
                if (await this.confirmationMessage('Are you sure you want to delete this booking?')) {
                    this.deleteBooking(id)
                        .then(() => {
                            toastSuccess("Booking has been deleted.");
                        })
                        .catch(() => {
                            toastFailure("Something went wrong.");
                        });
                }
            },
            async editSubmit() {

                this.isEditLoading = true;

                if (
                    this.edited.cancelled &&
                    !(await this.confirmationMessage('Are you sure you want to cancel this booking ' + (this.edited.boat === 0 && this.edited.refundInshore ? 'and fully refund' : '') + '?'))
                ) {
                    this.isEditLoading = false;
                    return false;
                }

                if( this.edited.boatCapacity < this.edited.details ){
                    this.showError({message: 'Max. guests number is '+this.edited.boatCapacity+' for this boat.'});
                    this.isEditLoading = false;
                    return false;
                }

                this.updateBooking({
                    id: this.edited.id,
                    date: (this.edited.cancelled === true && this.edited.date === null) ? this.edited.beforeDate : this.edited.date,
                    time: this.edited.time ? this.edited.time.value : null,
                    charter_package: this.edited.charterPackage,
                    status: this.edited.status.id,
                    cancelled: this.edited.cancelled,
                    refund_inshore: this.edited.refundInshore,
                    details: this.edited.details,
                    send_survey: false,
                    trip_id: this.thisBoatTripNameId[this.edited.charterPackage],
                    boat_id: this.edited.boat === 0 ? 0 : this.edited.boatObject.id,
                    duration: this.duration
                })
                    .then((response) => {

                        let emailsError = false;

                        if( response.emails_errors ){
                            emailsError = {
                                message:'Your changes have been updated, but we couldn\'t send the emails because of technical error.'
                            };
                        }

                        if (this.edited.cancelled) {
                            if( emailsError ){
                                this.showError(emailsError, 1);
                            }
                            else{
                                toastSuccess("This booking has been canceled.");
                            }
                        }
                        else {
                            if( emailsError ){
                                this.showError(emailsError, 1);
                            }
                            else{
                                toastSuccess("Your changes have been updated.");
                            }
                        }

                        this.$root.$emit("bv::hide::modal", "edit-modal");
                    })
                    .catch((error) => {
                        this.showError(error.response.data);
                    })
                    .finally(() => {
                        this.isEditLoading = false;
                    });
            },
            addPaymentSubmit() {
                this.isAddPaymentLoading = true;

                this.updateBooking({
                    id: this.payment.bookingId,
                    status: this.payment.type.value,
                    by_manual_payment: true,
                })
                    .then((response) => {
                        this.$root.$emit("bv::hide::modal", "add-payment-modal");

                        let emailsError = false;

                        if( response.emails_errors ){
                            emailsError = {
                                message:'Your changes have been updated, but we couldn\'t send the emails because of technical error.'
                            };
                            this.showError(emailsError, 1);
                        }

                        axios.post('api/write-payment-history', {
                            booking_id: this.payment.bookingId,
                            amount: this.payment.amount,
                            status: this.payment.type.value,
                            method: this.payment.method,
                        }).then((response) => {
                            toastSuccess("Payment successfully added");
                        }).catch((error) => {
                            this.showError(error.response.data);
                        });
                    })
                    .catch((error) => {
                        this.showError(error.response.data);
                    })
                    .finally(() => {
                        this.isAddPaymentLoading = false;
                    });
            },
            dateDisabled(date) {
                return checkReservedDate(date, this.blockDates, this.edited, this.boatNamesTripsPictures, this.thisBoatTripNameId);
            },
            onColumnFilter(params) {
                this.changeUrl(params.columnFilters);
            },
            changeUrl(values) {
                const searchParams = new URLSearchParams(window.location.search);

                /* eslint-disable-next-line */
                for (const [key, value] of Object.entries(values)) {
                    searchParams.set(key, value);
                }

                window.history.pushState(
                    {},
                    null,
                    `${this.$route.path}?${searchParams.toString()}`
                );
            },
            disableSelectedDate(){
                this.edited.date = null;
                this.datepickerPlaceholder = 'Please select a new date';
            },
            loadBlockDates(event, modalOpened = false) {
                if (this.edited.charterPackage) {
                    const editedDate = this.edited.date;
                    //this.edited.date = null;
                    this.datepickerPlaceholder = "Loading...";
                    this.isEditLoading = true;
                    this.isDatepickerDisabled = true;
                    axios
                        .get(
                            `api/bookings/block-dates/${this.edited.boatObject.id}/${this.edited.charterPackage}`
                        )
                        .then((response) => {

                            this.datepickerPlaceholder = 'Please choose the date';
                            const blockDates = {};

                            for (const date of response.data) {

                                if( this.edited.date == date.date && this.edited.date != this.edited.beforeDate ){
                                    this.disableSelectedDate();
                                }

                                if (!blockDates[date.date]) {
                                    blockDates[date.date] = new Array();
                                }

                                blockDates[date.date].push(date);
                            }

                            this.blockDates = blockDates;
                            this.isEditLoading = false;
                            this.isDatepickerDisabled = false;

                            if( this.dateDisabled(this.edited.date)  ){
                                this.disableSelectedDate();
                            }

                            if (modalOpened || !Object.keys(this.blockDates).includes(editedDate)) {
                                this.edited.date = editedDate;
                            }

                        })
                        .catch(() => {
                            toastFailure("Something went wrong.");
                        });
                }
            },
            isSelectable(time) {
                if (this.blockDates[this.edited.date]) {
                    /* eslint-disable-next-line */
                    return this.blockDates[this.edited.date].includes(time) ? false : true;
                }
                return true;
            },
            timesDisabled(option) {
                return option.selectable;
            },
            clickRow(params) {
                if (params.event.target.classList.contains("payment-status")) {
                    this.getPaymentHistory(params.row);
                    return;
                }

                if (params.event.target.classList.contains("customer-name")) {
                    this.fieldCustomerDetails(params.row);
                }
            },
            fieldPaymentHistory(booking) {
                this.getPaymentHistory(booking)
            },
            getPaymentHistory(booking) {

                let bookingId = booking.id;
                this.$root.$emit('bv::show::modal', 'payment-history-modal');
                this.isPaymentHistoryLoading = true;
                this.bookingLogs = booking.logs;
                axios
                    .get(
                        `api/payment-history/${bookingId}`
                    )
                    .then(response => {
                        this.paymentsHistory = response.data.data;

                        let alreadyPaidTmp = 0;
                        let refunded = 0;
                        this.paymentsHistory.map(function(payment){
                            alreadyPaidTmp += payment.paid;
                            refunded += payment.amount_refunded;
                        });

                        this.alreadyPaid = alreadyPaidTmp;
                        this.refundedAmount = refunded;
                        this.totalPrice = this.calculatePrice(booking);
                        this.remainingToPay = this.totalPrice - (this.alreadyPaid - refunded);
                        this.paymentStatus = booking.status;
                        this.isPaymentHistoryLoading = false;
                    })
                    .catch(() => {
                        toastFailure("Something went wrong while loading the payments.")
                    });
            },
            confirmationMessage(message) {
                return this.$bvModal.msgBoxConfirm(message, {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                });
            },
            dateChanged() {
                this.edited.time = null;
            },
            fieldCustomerDetails(row) {
                this.customer.name = row.customer_name;
                this.customer.email = row.customer_email;
                this.customer.phone = row.phone;
                this.customer.stripe_id = row.customer_stripe_id;

                this.$root.$emit('bv::show::modal', 'customer-modal');
            },

            calculatePrice(booking) {

                const selectedBoatTripNameId = [];
                const thisBoatPrices = [];
                const thisBoatAdditionalPrices = [];

                this.boatNamesTripsPictures[booking.boat_id - 1].trips.map(function(trip){
                    thisBoatPrices[trip.name] = trip.price;
                    selectedBoatTripNameId[trip.name] = trip.id;
                    thisBoatAdditionalPrices[trip.name] = trip.additional_price == null ? 0 : trip.additional_price;
                });

                if (booking.charter_package === HARBOR_CRUISE) return 800;
                if (booking.boat_id === TEASER_ID && booking.details > 6) {
                    const additionalPrice = thisBoatAdditionalPrices[booking.charter_package];
                    return thisBoatPrices[booking.charter_package] + additionalPrice * (booking.details - 6);
                }

                return thisBoatPrices[booking.charter_package];

            }

        },
    };
</script>

<style>
    .ul_payments{
        padding:0px;
        list-style-type: none;
    }

    .refund_amount_field{
        height: 23px !important;
        width:80px !important;
    }
</style>
